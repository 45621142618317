.ql-editor {
    font-family: "Karla", "Sora", sans-serif;
}

.ql-picker.ql-font .ql-picker-item:before {
    content: attr(data-value) !important;
    font-size: 14px;
}

.ql-picker.ql-font .ql-active {
    font-size: 0;
}

.ql-picker.ql-font .ql-active:before {
    content: attr(data-value) !important;
    font-size: 14px;
}

.ql-picker-options {
    max-height: 250px;
    overflow-y: scroll;
}

/* Text align fix */
.ql-align-center {
    text-align: center;
}
.ql-align-left {
    text-align: left;
}
.ql-align-right {
    text-align: right;
}
.ql-align-justify {
    text-align: justify;
}

.rich-text a {
    color: -webkit-link;
    text-decoration: underline;
}

/* Font-Families names */
.ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-picker.ql-font .ql-picker-item[data-value]::before {
    content: attr(data-value) !important;
}

/* Font-Sizes names */
.ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-picker.ql-size .ql-picker-item[data-value]::before {
    content: attr(data-value) !important;
}

/* Actual font sizes */
.ql-size-8pt,
.ql-picker.ql-size .ql-picker-item[data-value="8pt"]::before {
    font-size: 8pt;
}
.ql-size-10pt,
.ql-picker.ql-size .ql-picker-item[data-value="10pt"]::before {
    font-size: 10pt;
}
.ql-size-12pt,
.ql-picker.ql-size .ql-picker-item[data-value="12pt"]::before {
    font-size: 12pt;
}
.ql-size-14pt,
.ql-picker.ql-size .ql-picker-item[data-value="14pt"]::before {
    font-size: 14pt;
}
.ql-size-16pt,
.ql-picker.ql-size .ql-picker-item[data-value="16pt"]::before {
    font-size: 16pt;
}
.ql-size-18pt,
.ql-picker.ql-size .ql-picker-item[data-value="18pt"]::before {
    font-size: 18pt;
}
.ql-size-20pt,
.ql-picker.ql-size .ql-picker-item[data-value="20pt"]::before {
    font-size: 20pt;
}
.ql-size-22pt,
.ql-picker.ql-size .ql-picker-item[data-value="22pt"]::before {
    font-size: 22pt;
}
.ql-size-24pt,
.ql-picker.ql-size .ql-picker-item[data-value="24pt"]::before {
    font-size: 24pt;
}
.ql-size-26pt,
.ql-picker.ql-size .ql-picker-item[data-value="26pt"]::before {
    font-size: 26pt;
}
.ql-size-28pt,
.ql-picker.ql-size .ql-picker-item[data-value="28pt"]::before {
    font-size: 28pt;
}
.ql-size-30pt,
.ql-picker.ql-size .ql-picker-item[data-value="30pt"]::before {
    font-size: 30pt;
}
.ql-size-32pt,
.ql-picker.ql-size .ql-picker-item[data-value="32pt"]::before {
    font-size: 32pt;
}
.ql-size-34pt,
.ql-picker.ql-size .ql-picker-item[data-value="34pt"]::before {
    font-size: 34pt;
}
.ql-size-36pt,
.ql-picker.ql-size .ql-picker-item[data-value="36pt"]::before {
    font-size: 36pt;
}
.ql-size-38pt,
.ql-picker.ql-size .ql-picker-item[data-value="38pt"]::before {
    font-size: 38pt;
}

.ql-picker.ql-font .ql-picker-label[data-value="Sora"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Sora"]::before {
    font-family: "Sora", cursive;
}

.ql-picker.ql-font .ql-picker-label[data-value="Karla"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Karla"]::before {
    font-family: "Karla", cursive;
}

/* Set content font-families */
.ql-font-Sora {
    font-family: "Sora", sans-serif;
}
.ql-font-Karla {
    font-family: "Karla", sans-serif;
}

.ql-snow .ql-tooltip {
    z-index: 10000;
}

blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

.rich-text p, .rich-text span {
    color: #646259 !important;
    font-size: 21px;
    line-height: 32px;
}

.rich-text a {
    color: #009fdf !important;
    text-decoration: none;
    font-weight: 700;
}

.rich-text h2, .rich-text h3, .rich-text h4, .rich-text h2 *, .rich-text h3 *, .rich-text h4 * {
    color: #151319 !important;
    font-family: "Sora", sans-serif !important;
    font-weight: bold !important;
    line-height: 1.5 !important;
}

.rich-text h2 {
    font-size: 28px;
}

.rich-text h3 {
    font-size: 25px;
}

.rich-text h4 {
    font-size: 22px;
}

.rich-text ul {
    color: #646259;
    font-family: "Karla";
    font-size: 21px;
}
